import React from "react"
import CopyIcon from "../../assets/icons/CopyIcon"

export default function CopyRecipe() {
  function copyInput() {
    let allIngredients = document.querySelectorAll(".ingredient")

    let textArray = []
    allIngredients.forEach(ingredient => {
      textArray.push(" " + ingredient.title)
    })

    navigator.clipboard.writeText(textArray.toString()).then(
      function () {
        document
          .querySelector(".copy_message")
          .classList.remove("copied_message")

        document.querySelector(".copy_message").classList.add("copied_message")
        document
          .querySelector(".copy_message")
          .addEventListener("animationend", () => {
            document
              .querySelector(".copy_message")
              .classList.remove("copied_message")
          })
      },
      function (err) {
        console.error("Async: Could not copy text: ", err)
      },
    )
  }
  return (
    <div className="buttonWrapper">
      <button aria-label="kopier opskrift" onClick={copyInput}>
        Kopier <CopyIcon />
      </button>
    </div>
  )
}
