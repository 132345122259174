import { Badge, Stack } from "@chakra-ui/layout"
import { Link } from "gatsby"
import React from "react"

export default function RecipeTags({ tags }) {
  return (
    <>
      <Stack
        justifyContent="center"
        direction="row"
        flexWrap="wrap"
        padding={"0px 30px"}
      >
        {tags.map(tag => {
          return (
            <Link key={tag.value} to={"/kategori/" + tag.value}>
              <Badge>{tag.label}</Badge>{" "}
            </Link>
          )
        })}
      </Stack>
    </>
  )
}
