export function recipeSchema(
  metaTitle,
  metaDescription,
  recipeImage,
  publishedAt,
  amountOfPeople,
  ingredients,
  time,
  procedure,
  slug,
  tags,
) {
  let recipeInstructions = []
  let categories = []
  let createCooktime = "PT" + time.replace("+", "") + "M"

  tags.forEach(category => {
    categories.push(category.value)
  })

  let hashtagCounter = 0

  procedure.forEach((step, i) => {
    if (step.includes("#")) {
      hashtagCounter++
      return
    }

    i++
    i = i - hashtagCounter
    recipeInstructions.push({
      "@type": "HowToStep",
      name: i + ". trin:",
      text: step,
      url: "https://www.gastrologik.dk/" + slug.current + "#step" + i,
      image: recipeImage.verticalImage.asset.url,
    })
  })

  const recipeMarkup = {
    "@context": "https://schema.org/",
    "@type": "Recipe",
    name: metaTitle,
    image: [recipeImage.verticalImage.asset.url],
    description: metaDescription,

    keywords: "opskrift," + categories.join(","),
    author: {
      "@type": "Person",
      name: "Mathilde Lauth | Gastrologik",
    },
    datePublished: publishedAt,
    prepTime: createCooktime,
    cookTime: createCooktime,
    totalTime: createCooktime,

    recipeCategory: categories.join(","),
    recipeCuisine: "Multi",
    recipeYield: parseInt(amountOfPeople).toString(),

    recipeIngredient: ingredients,
    recipeInstructions: recipeInstructions,
  }
  return recipeMarkup
}

export function recipeBreadCrumb(slug) {
  const breadCrumbRecipe = {
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "gastrologik.dk",
        item: "https://www.gastrologik.dk/",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "blog",
        item: "https://www.gastrologik.dk/alle-opskrifter/",
      },
      {
        "@type": "ListItem",
        position: 3,
        name: slug.current,
        item: "https://gastrologik.dk/" + slug.current + "/",
      },
    ],
  }
  return breadCrumbRecipe
}
