import React from "react"
// import { Link } from "gatsby"

import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/sanity/serializers"
import SimpleFooter from "../components/global/SimpleFooter"
import Procedure from "../components/pages/recipeComponents/procedure"
import AdditionalRecipes from "../components/pages/recipeComponents/AdditionalRecipes"
import IngrediensList from "../components/pages/recipeComponents/IngrediensList"
import RecipeTags from "../components/pages/recipeComponents/RecipeTags"
import { Divider } from "@chakra-ui/layout"
import { recipeSchema, recipeBreadCrumb } from "../utils/schemas/recipeSchema"
import ExtractText from "../components/sanity/ExtractText"
import PostLayout from "../components/global/PostLayout"
import PrinterIcon from "../components/assets/icons/PrinterIcon"
export default function Recipe({ data }) {
  const {
    slug,
    title,
    seo,
    _rawBody,
    recipeImage,
    time,
    procedure,
    ingredients,
    publishedAt,
    tags,
    amountOfPeople,
  } = data.sanityRecipes

  const metaTitle = !seo ? title : seo.seoTitle
  const metaDescription = !seo
    ? ExtractText(_rawBody).substring(0, 155)
    : seo.seoTitle

  function initiatePrint() {
    window.print()
  }

  return (
    <PostLayout showFooter={false}>
      <article className="recipe postLayout">
        <SearchEngineOptimizaion
          title={metaTitle}
          description={metaDescription}
          recipeMarkup={recipeSchema(
            metaTitle,
            metaDescription,
            recipeImage,
            publishedAt,
            amountOfPeople,
            ingredients,
            time,
            procedure,
            slug,
            tags,
          )}
          breadCrumbRecipe={recipeBreadCrumb(slug)}
        />
        <div className="verticalImageContainer">
          <GatsbyImage
            image={recipeImage.verticalImage.asset.gatsbyImageData}
            alt={title}
            loading="lazy"
            className="verticalImage"
          />
        </div>
        <div className="rightWrapper hideScrollbar">
          <div
            className={`horisontalImageContainer ${
              !recipeImage.horisontalImage && "hideOnDesktop"
            }`}
          >
            <GatsbyImage
              image={
                recipeImage.horisontalImage
                  ? recipeImage.horisontalImage.asset.gatsbyImageData
                  : recipeImage.verticalImage.asset.gatsbyImageData
              }
              alt={title}
              className={`horisontalImage`}
              loading="lazy"
            />
          </div>
          <div className="recipeTitleWrapper removeMargin">
            <h1>{title}</h1>
            <div className="spanLineLeft"></div>
          </div>
          <div className="recipeBlockContent">
            <BlockContent
              blocks={_rawBody}
              projectId="2aoj8j2d"
              dataset="gastrologik"
              serializers={serializers}
            />
          </div>
          <Divider width="calc(100% - 60px)" m="30px auto 10px" />
          <RecipeTags tags={tags} />
          <div className="recipeInfo padding">
            <IngrediensList data={data} />

            <Procedure data={data} />
          </div>
          <div className="padding">
            <GatsbyImage
              loading="lazy"
              image={recipeImage.verticalImage.asset.gatsbyImageData}
              alt={title}
              className="verticalImage displayOnMobile "
            />
          </div>
          <div className="print">
            <button
              aria-label="print opskrift"
              onClick={initiatePrint}
              className="printButton"
            >
              <PrinterIcon />
            </button>
          </div>
          <Divider width="calc(100% - 60px)" m="30px auto 40px" />
          <div className="padding">
            <AdditionalRecipes
              recipes={data.newest.edges}
              title={"Flere lækre opskrifter til dig"}
            />
          </div>
          <Divider width="calc(100% - 60px)" m="30px auto 40px" />
          <div className="padding">
            <AdditionalRecipes
              recipes={data.seasonal.edges}
              title={"Opskrifter der er i sæson"}
            />
          </div>
          <Divider width="calc(100% - 60px)" m="30px auto 10px" />
          <SimpleFooter soMeLinks={data.sanityCompanyInfo} />
        </div>

        <span hidden className="hiddenField1">
          {amountOfPeople}
        </span>
        <form action="">
          <input
            readOnly
            aria-label="hidden field (only used for copying ingredientlist)"
            type="text"
            className="hiddenField2"
            value=""
          />
        </form>
        <span className="copy_message">Ingredienslisten blev kopieret</span>
      </article>
    </PostLayout>
  )
}

export const Query = graphql`
  query ($Slug: String, $Season: String, $Tags: String) {
    sanityRecipes(slug: { current: { eq: $Slug } }) {
      tags {
        value
        label
      }
      slug {
        current
      }
      seo {
        seoDescription
        seoTitle
      }
      title
      time
      publishedAt(formatString: "YYYY-MM-DD")
      procedure
      ingredients
      amountOfPeople
      recipeImage {
        verticalImage {
          asset {
            url
            gatsbyImageData(
              width: 300
              backgroundColor: "#f9f9f9"
              placeholder: NONE
            )
          }
        }
        horisontalImage {
          asset {
            url
            gatsbyImageData(
              height: 300
              backgroundColor: "#f9f9f9"
              placeholder: NONE
            )
          }
        }
      }
      _rawBody(resolveReferences: { maxDepth: 10 })
    }
    site {
      siteMetadata {
        title
      }
    }
    sanityCompanyInfo {
      facebook
      instragram
      pinterest
    }

    related: allSanityRecipes(
      limit: 6
      filter: { tags: { elemMatch: { value: { eq: $Tags } } } }
    ) {
      ...recipeInfo
    }
    seasonal: allSanityRecipes(
      filter: {
        tags: { elemMatch: { value: { eq: $Season } } }
        slug: { current: { ne: $Slug } }
      }
      sort: { publishedAt: ASC }
      limit: 6
    ) {
      ...recipeInfo
    }
    newest: allSanityRecipes(
      filter: { slug: { current: { ne: $Slug } } }
      sort: { publishedAt: DESC }
      limit: 6
    ) {
      ...recipeInfo
    }
  }
  fragment recipeInfo on SanityRecipesConnection {
    edges {
      node {
        title
        recipeImage {
          verticalImage {
            asset {
              gatsbyImageData(
                width: 300
                placeholder: NONE
                backgroundColor: "#f9f9f9"
                formats: AVIF
              )
            }
          }
        }
        slug {
          current
        }
      }
    }
  }
`
