import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

export default function AdditionalRecipes({ recipes, title }) {
  return (
    <section className="newestRecipesSection">
      <h3>{title}</h3>
      <div className="newestRecipes">
        {recipes.map(recipe => {
          const { title, recipeImage, slug } = recipe.node
          return (
            <div className="newRecipe" key={title}>
              <Link
                aria-label={"Link til" + title}
                to={"/" + slug.current + "/"}
              >
                <GatsbyImage
                  loading="lazy"
                  image={recipeImage.verticalImage.asset.gatsbyImageData}
                  alt={title}
                />
              </Link>
              <Link
                aria-label={"Link til" + title}
                to={"/" + slug.current + "/"}
              >
                <p>{title}</p>
              </Link>
            </div>
          )
        })}
      </div>
    </section>
  )
}
