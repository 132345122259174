import React, { useState } from "react"
// import { Link } from "gatsby"

import { Button } from "@chakra-ui/button"
import CopyRecipe from "./CopyRecipe"
import PlusIcon from "../../assets/icons/PlusIcon"
import MinusIcon from "../../assets/icons/MinusIcon"
import CheckBoxIcon from "../../assets/icons/CheckBoxIcon"
export default function IngrediensList({ data }) {
  const [amountOfPeople, setAmountOfPeople] = useState(
    parseInt(data.sanityRecipes.amountOfPeople),
  )

  const staticNumber = parseInt(data.sanityRecipes.amountOfPeople)

  const cleanedQuestionText = data.sanityRecipes.amountOfPeople.replace(
    /[0-9]/g,
    "",
  )

  function toFixedIfNecessary(value) {
    return +parseFloat(value).toFixed(2)
  }

  function procudureClicked(e) {
    e.target.classList.toggle("checkedProcuedureCheckbox")
  }

  return (
    <div className="ingrediensWrapper">
      <h2>Ingredienser</h2>
      <div className="ingrediensButtonWrapper">
        <Button
          size="sm"
          p="0"
          variant="ghost"
          m="0 10px"
          borderRadius="2px"
          className="minusOneMore"
          aria-label="Fjern en mere"
          isDisabled={amountOfPeople === 1}
          onClick={() => setAmountOfPeople(amountOfPeople + -1)}
        >
          <MinusIcon />
        </Button>
        <p>
          {amountOfPeople} {cleanedQuestionText}
        </p>
        <Button
          size="sm"
          p="0"
          variant="ghost"
          m="0 10px"
          borderRadius="2px"
          aria-label="Tilføj en mere"
          className="minusOneMore"
          onClick={() => setAmountOfPeople(amountOfPeople + 1)}
        >
          <PlusIcon />
        </Button>
      </div>
      <ul>
        {data.sanityRecipes.ingredients.map(function (ingredient, i) {
          if (ingredient.includes("#"))
            return (
              <li
                key={`ingredient${i}`}
                className="ingredientTitle"
                title={ingredient}
              >
                <h3>{ingredient.replace("#", "")}</h3>
              </li>
            )

          const adjustCommas = ingredient.replace(/,/g, ".")

          const forOnePerson = parseFloat(adjustCommas) / staticNumber
          const cleanIngredienseText = ingredient.replace(/[0-9]./g, "")
          const calculatedValue = forOnePerson * amountOfPeople

          const fixedNumber = !isNaN(calculatedValue)
            ? toFixedIfNecessary(calculatedValue)
            : ""

          return (
            <li
              key={`ingredient${i}`}
              className="ingredient"
              value={fixedNumber}
              title={fixedNumber + " " + cleanIngredienseText}
            >
              <button
                className="procedureCheckbox uncheckedProcuedureCheckbox listGridItem"
                onClick={procudureClicked}
              >
                <CheckBoxIcon />
                {fixedNumber} {cleanIngredienseText}
              </button>
            </li>
          )
        })}
      </ul>

      <CopyRecipe />
    </div>
  )
}
