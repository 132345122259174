import React from "react"
import TimerIcon from "../../assets/icons/TimerIcon"
import CheckBoxIcon from "../../assets/icons/CheckBoxIcon"

export default function Procedure({ data }) {
  function procudureClicked(e) {
    e.target.classList.toggle("checkedProcuedureCheckbox")
  }
  return (
    <div className="procedureWrapper">
      <div className="titleAndTime">
        <h2>Fremgangsmåde</h2>{" "}
        <span>
          <TimerIcon />
          {data.sanityRecipes.time} min.
        </span>
      </div>
      <ul>
        {data.sanityRecipes.procedure.map(function (step, i) {
          if (step.includes("#"))
            return (
              <li key={`procedure${i}`} className="procedureTitle" title={step}>
                <h3>{step.replace("#", "")}</h3>
              </li>
            )
          i++
          return (
            <li key={`step${i}`} id={"step" + i}>
              <button
                className="procedureCheckbox uncheckedProcuedureCheckbox listGridItem"
                onClick={procudureClicked}
              >
                <CheckBoxIcon /> {step}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
